require('./bootstrap');

require('floatthead/dist/jquery.floatThead');

require('dropzone/dist/dropzone');

require('bootstrap-autocomplete/dist/latest/bootstrap-autocomplete.min');

require('chart.js/dist/chart');

require('chartjs-gauge/dist/chartjs-gauge');

